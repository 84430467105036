
import RightSidebarContent from "@/components/portals/RightSidebarContent"
import DateTimePicker from "@evercam/shared/components/DateTimePicker"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import Vue from "vue"
import {
  AvailableDaysResponsePayload,
  AvailableHoursResponsePayload,
  CameraStatus,
} from "@evercam/shared/types"
import { camelizeKeys } from "humps"
import { useSnapshotStore } from "@evercam/dashboard/stores/snapshots"
import { mapStores } from "pinia"
import { useLayoutStore } from "@evercam/dashboard/stores/layout"
import { useCameraStore } from "@evercam/dashboard/stores/camera"
import { useAccountStore } from "@evercam/dashboard/stores/account"
import { Moment } from "moment-timezone"
import { debounce } from "@evercam/shared/utils"
import EvercamPlayer from "@evercam/shared/components/EvercamPlayer.vue"
import CameraStatusSnackbar from "@/components/CameraStatusSnackbar"
import PlayersActionButtons from "@/components/recordings/player/PlayersActionButtons"

import { useRecordingsStore } from "@evercam/dashboard/stores/recordings"
import { useEventsStore } from "@evercam/dashboard/stores/events"

export default Vue.extend({
  components: {
    EvercamPlayer,
    DateTimePicker,
    RightSidebarContent,
    CameraStatusSnackbar,
    PlayersActionButtons,
  },
  props: {
    isSideBar: {
      type: Boolean,
      default: true,
    },
    isEmbedded: {
      type: Boolean,
      default: false,
    },
    isDarkMode: {
      type: Boolean,
      default: false,
    },
    cameraId: {
      type: String,
      default: "",
    },

    refreshRate: {
      type: Number,
      default: 12,
    },

    allowZoom: {
      type: Boolean,
      default: true,
    },

    allowPlayPause: {
      type: Boolean,
      default: true,
    },
    allowDownload: {
      type: Boolean,
      default: true,
    },
    allowFullscreen: {
      type: Boolean,
      default: true,
    },
    delayBy: {
      type: [Number, undefined],
      default: undefined,
    },
    dateRange: {
      type: [Array, undefined],
      default: undefined,
    },
  },
  data() {
    return {
      availableDays: [],
      availableHours: [],
      isLoadingDays: false,
      isLoadingHours: false,
      calendarSelectedTimestamp: new Date().toISOString(),
      playerWidth: "100%",
      playerHeight: "100%",
      showLogo: true,
      showCameraName: true,
      minHour: 0,
      maxHour: 23,
    }
  },
  computed: {
    ...mapStores(
      useSnapshotStore,
      useLayoutStore,
      useCameraStore,
      useAccountStore,
      useRecordingsStore,
      useEventsStore
    ),
    showAlert(): boolean {
      return [
        CameraStatus.Offline,
        CameraStatus.OfflineScheduled,
        CameraStatus.UnderMaintenance,
        CameraStatus.WaitingForSiteVisit,
        CameraStatus.Waiting,
        CameraStatus.OnHold,
      ].includes(this.cameraStore.selectedCamera?.status)
    },
    snapshotsInterval(): { from: string; to: string } {
      return {
        from: this.$moment
          .tz(
            this.recordingsStore.selectedTimestamp,
            this.cameraStore.selectedCamera.timezone
          )
          .startOf("hour")
          .utc()
          .format(),
        to: this.$moment
          .tz(
            this.recordingsStore.selectedTimestamp,
            this.cameraStore.selectedCamera.timezone
          )
          .endOf("hour")
          .utc()
          .format(),
      }
    },
    logoDimensions(): { width: string; height: string } {
      return this.accountStore.isRecordingWidget
        ? { width: "60px", height: "60px" }
        : { width: "40px", height: "40px" }
    },
    maxDate(): string {
      let maxDate
      if (this.hasDateRange) {
        maxDate = this.$moment.utc(this.dateRange[1])
      } else {
        const latestDate = this.cameraStore.selectedCamera?.isOnline
          ? new Date()
          : this.cameraStore.selectedCamera?.lastOnlineAt
        maxDate = this.$moment.tz(latestDate, this.timezone)
      }

      if (this.delayBy) {
        maxDate.subtract(this.delayBy, "millisecond")
      }

      return maxDate.toISOString()
    },
    minDate(): string {
      let date = this.$moment(
        Math.min(
          new Date(
            this.snapshotStore.oldestSnapshotTimestamp(this.cameraId)
          ).getTime(),
          new Date(this.cameraStore.selectedCamera?.createdAt).getTime()
        )
      )

      if (this.hasDateRange && this.rangeStart?.isAfter(date)) {
        return this.rangeStart.toISOString()
      }

      return date.toISOString()
    },
    disableOldest(): boolean {
      let date = this.$moment.tz(
        this.snapshotStore.oldestSnapshotTimestamp(this.cameraId),
        this.timezone
      )

      if (this.hasDateRange) {
        date = this.rangeStart.set("hour", this.minHour ?? 0)
      }

      return this.$moment
        .tz(this.calendarSelectedTimestamp, this.timezone)
        .isSame(date)
    },
    disableLatest(): boolean {
      let date = this.$moment.tz(
        this.snapshotStore.latestSnapshotTimestamp(this.cameraId),
        this.timezone
      )

      if (this.hasDateRange) {
        date = this.rangeEnd?.set("hour", this.maxHour ?? 23)
      }

      return this.$moment
        .tz(this.calendarSelectedTimestamp, this.timezone)
        .isSame(date)
    },
    placeholderImage(): string {
      return this.cameraStore.selectedCamera?.largeThumbnailUrl
    },
    timezone(): string {
      return this.cameraStore.selectedCameraTimezone
    },
    zonedSelectedTimestamp(): Moment {
      return this.$moment(this.recordingsStore.selectedTimestamp).tz(
        this.timezone
      )
    },
    selectedDay(): string {
      return this.zonedSelectedTimestamp.format("DD")
    },
    selectedMonth(): string {
      return this.zonedSelectedTimestamp.format("MM")
    },
    selectedYear(): string {
      return this.zonedSelectedTimestamp.format("YYYY")
    },
    filteredAvailableDays(): number[] {
      if (this.delayBy || this.hasDateRange) {
        return this.availableDays.filter((d) =>
          this.isDateRestricted(
            `${this.selectedYear}-${this.selectedMonth}-${d}`
          )
        )
      } else {
        return this.availableDays
      }
    },
    filteredAvailableHours(): number[] {
      if (this.delayBy) {
        return this.availableHours.filter((h) =>
          this.isDateRestricted(
            `${this.selectedYear}-${this.selectedMonth}-${
              this.selectedDay
            }T${String(h).padStart(2, "0")}`
          )
        )
      } else {
        return this.availableHours
      }
    },
    playerCols(): number {
      if (!this.isEmbedded && this.isSideBar) {
        return 8
      } else {
        return 12
      }
    },
    hasDateRange(): boolean {
      return this.dateRange?.length == 2
    },
    rangeStart(): Moment | null {
      if (!this.hasDateRange) {
        return null
      }

      return this.$moment.utc(this.dateRange[0])
    },
    rangeEnd(): Moment | null {
      if (!this.hasDateRange) {
        return null
      }

      return this.$moment.utc(this.dateRange[1])
    },
  },
  watch: {
    cameraId: {
      immediate: true,
      async handler(cameraId) {
        await this.cameraStore.selectCamera(cameraId)
      },
    },
    "accountStore.isLiveViewWidget": {
      immediate: true,
      handler(value) {
        if (value) {
          this.recordingsStore.isLive = true
          this.recordingsStore.isPlaying = true
        } else {
          this.onInitCalendar()
          this.recordingsStore.isLive = false
          this.recordingsStore.isPlaying = false
        }
      },
    },
    calendarSelectedTimestamp: {
      handler(timestamp) {
        if (!this.cameraStore.selectedCamera && !timestamp) {
          return
        }
        this.recordingsStore.selectedTimestamp = this.$moment(timestamp)
          .tz(this.timezone)
          .startOf("hour")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      },
      immediate: true,
    },
    isSideBar: {
      handler(value) {
        if (value && this.isEmbedded) {
          this.layoutStore.enableRightSidebar()
        } else {
          this.layoutStore.disableRightSidebar()
        }
      },
      immediate: true,
    },
    delayBy: debounce(function () {
      // @ts-ignore
      this.onDelayChange()
    }),
    dateRange: debounce(function () {
      // @ts-ignore
      this.onDelayChange()
    }),
  },
  async created() {
    if (
      !this.snapshotStore.camerasLatestOldestSnapshots ||
      this.snapshotStore.camerasLatestOldestSnapshots[this.cameraId] ||
      !this.snapshotStore.latestSnapshotTimestamp(this.cameraId) ||
      !this.snapshotStore.oldestSnapshotTimestamp(this.cameraId) ||
      !this.accountStore.isLiveViewWidget ||
      this.delayBy ||
      this.hasDateRange
    ) {
      this.onDelayChange()
    }

    if (this.isEmbedded) {
      this.switchMode()
    }
    const query = camelizeKeys(this.$route.query)
    this.showCameraName = query.cameraName !== "false"
    this.showLogo = query.logo !== "false"
  },
  beforeDestroy() {
    this.layoutStore.disableRightSidebar()
  },
  mounted() {
    if (
      this.isSideBar &&
      !this.accountStore.isLiveViewWidget &&
      this.isEmbedded
    ) {
      this.layoutStore.enableRightSidebar()
    }
    if (!this.recordingsStore.isEdgeVideo) {
      this.cameraStore.setupSocketForSelectedCamera()
    }
    this.accountStore.isRecordingWidget = !this.accountStore.isLiveViewWidget
  },
  methods: {
    onResize({ contentRect }) {
      this.playerHeight = contentRect.height
      this.playerWidth = contentRect.width
    },
    toggleIosFullscreen() {
      this.cameraStore.isCameraTab = !this.cameraStore.isCameraTab
      this.layoutStore.toggleAppBarVisibility()
    },
    onMonthChange(month) {
      if (this.accountStore.isLiveViewWidget) {
        return
      }
      this.fetchAvailableDays(month)
    },
    onDayChange() {
      if (this.accountStore.isLiveViewWidget) {
        return
      }
      this.fetchAvailableHours(this.calendarSelectedTimestamp)
    },
    async onDelayChange() {
      this.snapshotStore.camerasLatestOldestSnapshots[this.cameraId] = {
        ...this.snapshotStore.camerasLatestOldestSnapshots[this.cameraId],
        maxDate: this.maxDate as string,
      }
      await this.snapshotStore.updateLatestSnapshot(this.cameraId)
      this.recordingsStore.selectedTimestamp =
        this.snapshotStore.latestSnapshotTimestamp(this.cameraId)

      if (this.hasDateRange) {
        this.minHour = (await this.fetchAllowedHours(this.rangeStart)).at(0)
        this.maxHour = (await this.fetchAllowedHours(this.rangeEnd)).at(-1)
        this.calendarSelectedTimestamp = this.rangeEnd.toISOString()
      }
    },
    async fetchAllowedHours(datetime) {
      const year = datetime.format("YYYY"),
        month = datetime.format("MM"),
        day = datetime.format("DD")

      try {
        const { hours }: AvailableHoursResponsePayload =
          await EvercamApi.recordings.availableHours({
            cameraId: this.cameraStore.selectedCameraExid || this.cameraId,
            year,
            month,
            day,
          })

        return hours
      } catch (_e) {
        return []
      }
    },
    isDateRestricted(d) {
      return this.$moment.tz(d, this.timezone).isSameOrBefore(this.maxDate)
    },
    async fetchAvailableDays(date) {
      this.isLoadingDays = true
      const datetime = this.$moment(date),
        year = datetime.format("YYYY"),
        month = datetime.format("MM")

      try {
        const { days }: AvailableDaysResponsePayload =
          await EvercamApi.recordings.availableDays({
            cameraId: this.cameraStore.selectedCameraExid || this.cameraId,
            year,
            month,
          })
        this.availableDays = days
      } catch (err) {
        this.$errorTracker.save(err)
      } finally {
        this.isLoadingDays = false
      }
    },
    async fetchAvailableHours(date: string) {
      this.isLoadingHours = true
      const datetime = this.$moment(date),
        year = datetime.format("YYYY"),
        month = datetime.format("MM"),
        day = datetime.format("DD")

      try {
        const { hours }: AvailableHoursResponsePayload =
          await EvercamApi.recordings.availableHours({
            cameraId: this.cameraStore.selectedCameraExid || this.cameraId,
            year,
            month,
            day,
          })

        this.availableHours = hours
        if (
          !hours.includes(
            this.$moment
              .tz(this.calendarSelectedTimestamp, this.timezone)
              .hour()
          )
        ) {
          this.calendarSelectedTimestamp = this.$moment
            .tz(this.calendarSelectedTimestamp, this.timezone)
            .set("hour", hours[0])
            .toISOString()
        }
      } catch (err) {
        this.$errorTracker.save(err)
      }
      this.isLoadingHours = false
    },
    oldestSnapshot() {
      const date = this.$moment.tz(
        this.snapshotStore.oldestSnapshotTimestamp(this.cameraId),
        this.timezone
      )

      if (this.hasDateRange) {
        this.calendarSelectedTimestamp = this.$moment
          .tz(this.dateRange[0], this.timezone)
          .set("hour", this.minHour ?? 0)
          .toISOString()
      } else {
        this.calendarSelectedTimestamp = date.toISOString()
      }
    },
    latestSnapshot() {
      const date = this.$moment.tz(
        this.snapshotStore.latestSnapshotTimestamp(this.cameraId),
        this.timezone
      )

      if (this.hasDateRange) {
        this.calendarSelectedTimestamp = this.rangeEnd
          .set("hour", this.maxHour ?? 23)
          .toISOString()
      } else {
        this.calendarSelectedTimestamp = date.toISOString()
      }
    },
    switchMode() {
      this.$theme.switchTheme({
        isDark: this.isDarkMode,
        disablePersistToLocalStorage: this.isEmbedded,
      })
    },
    getFormattedTimestamp(timestamp) {
      return this.$moment(timestamp)
        .tz(this.cameraStore.selectedCameraTimezone)
        .startOf("hour")
        .format("YYYY-MM-DDTHH:mm:ssZ")
    },
    onInitCalendar() {
      let timestamp = (camelizeKeys(this.$route.query) as Record<string, any>)
        ?.dateTime
      if (timestamp && this.$moment(timestamp).isValid()) {
        this.calendarSelectedTimestamp = this.$moment(timestamp)
          .tz(this.cameraStore.selectedCameraTimezone)
          .toISOString()
        this.recordingsStore.selectedTimestamp =
          this.getFormattedTimestamp(timestamp)

        return
      }
      timestamp = this.$moment(
        this.snapshotStore.latestSnapshotTimestamp(this.cameraId)
      )
        .tz(this.cameraStore.selectedCameraTimezone)
        .toISOString()
      this.calendarSelectedTimestamp = this.$moment(timestamp).toISOString()
      this.recordingsStore.selectedTimestamp =
        this.getFormattedTimestamp(timestamp)

      this.fetchAvailableDays(this.calendarSelectedTimestamp)
      this.fetchAvailableHours(this.calendarSelectedTimestamp)
    },
  },
})
