
import Vue from "vue"
import Autocomplete from "@evercam/shared/components/Autocomplete"
import { useCameraStore } from "@/stores/camera"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import { CameraStatus } from "@evercam/shared/types/camera"
import { toTitleCase } from "@evercam/shared/utils"

export default Vue.extend({
  name: "CamerasAutocomplete",
  components: {
    Autocomplete,
  },
  props: {
    value: {
      type: [Array, Object],
      default: () => [],
    },
    filterCameras: {
      type: Function,
      default: (cameras) => cameras,
    },
    required: {
      type: Boolean,
      default: true,
    },
    showCameraDetails: {
      type: Boolean,
      default: false,
    },
    selectFirst: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedItems: null,
    }
  },
  computed: {
    ...mapStores(useCameraStore, useAccountStore),
    filteredCameras() {
      return this.filterCameras(this.cameraStore.cameras)
    },
    isMultipleSelection() {
      return Object.prototype.hasOwnProperty.call(this.$attrs, "multiple")
    },
  },
  watch: {
    value: {
      handler(value) {
        if (value) {
          this.selectedItems = value
        }
      },
      immediate: true,
      deep: true,
    },
    selectedItems: {
      handler(value) {
        if (value) {
          this.$emit("input", value)
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    if (this.selectFirst) {
      this.selectedItems = this.cameraStore.cameras?.[0]
    }
  },
  methods: {
    remove(item) {
      const index = this.selectedItems.indexOf(item?.exid)
      if (index > -1) {
        this.selectedItems.splice(index, 1)
      }
    },
    getFormattedCameraName(item) {
      if (!this.showCameraDetails) {
        return item.name
      }
      const status =
        item.status !== CameraStatus.Online
          ? `(${toTitleCase(item.status)})`
          : ""
      const isPublic = !item.isPublic ? "(Private)" : ""
      const projectName = `(${item.project?.name})`

      return `${item.name} ${projectName} ${status} ${isPublic}`
    },
  },
})
